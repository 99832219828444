Office Name,Office type,Office province,Phone number,Email address,Physical address,Website,Contact person,Link to Google Maps,Latitude,Longitude
Legal Aid SA Northern Cape/Western Cape Provincial Office,"
Legal Aid South Africa",Western Cape,(021) 697 5252,,"2nd Floor, Melofin Centre, Klipfontein Road, Athlone, 7764",www.legal-aid.co.za,Ms Nolitha Jali,,-33.9602738,18.50388
Legal Aid SA Athlone Local Office,"
Legal Aid South Africa",Western Cape,(021) 697 5252,,"2nd Floor, Melofin Centre, Klipfontein Road, Athlone, 7764",www.legal-aid.co.za,Ms Nomawethu Mtebele,,-33.9602738,18.50388
Legal Aid SA Mitchells Plain Satellite Office,"
Legal Aid South Africa",Western Cape,(021) 370 4220,,"Mitchells Plain Court, Mitchells Plain, 7785",www.legal-aid.co.za,,,-34.0483939,18.6238298
Legal Aid SA Bellville Local Office,"
Legal Aid South Africa",Western Cape,(021) 949 3062,,"Van Der Stel Building, 68 Durban Road, corner Voortrekker & Durban Roads, Bellville, 7535",www.legal-aid.co.za,Ms Ronelle Arendse,,-33.9005916,18.6275802
Legal Aid SA Caledon Local Office,"
Legal Aid South Africa",Western Cape,(028) 212 1815,,"22 Church Street, Caledon, 7230",www.legal-aid.co.za,Ms Netarie Verwey,,-34.228015,19.4586453
Legal Aid SA Swellendam Satellite Office,"
Legal Aid South Africa",Western Cape,(028) 514 3026,,"67 Voortrek Street, Swellendam, 6470",www.legal-aid.co.za,,,-34.0216358,20.4415468
Legal Aid SA Cape Town Local Office,"
Legal Aid South Africa",Western Cape,(021) 426 4126,,"3rd Floor, South African Reserve Bank Building, 60 St Georges Mall, Cape Town, 8001",www.legal-aid.co.za,Mr Elroy Machelm,,-33.9222205,18.4213456
Legal Aid SA George Local Office,"
Legal Aid South Africa",Western Cape,(044) 802 8600,,"Tommy Joubert Building, corner Craddock & Courtenay Streets, George, 6530",www.legal-aid.co.za,Ms Zukiswa Mavuso,,-33.9625893,22.4584012
Legal Aid SA Beaufort West Satellite Office,"
Legal Aid South Africa",Western Cape,(023) 415 1689,,"102 Bird Street, Beaufort West, 6970",www.legal-aid.co.za,,,-32.3525585,22.5840985
Legal Aid SA Oudtshoorn Satellite Office,"
Legal Aid South Africa",Western Cape,(044) 279 2255,,"Allied Building, Rooms 302 ,303 & 304, Kerk Street, Oudtshoorn",www.legal-aid.co.za,,,-33.585858,22.1851005
Legal Aid SA Riversdale Satellite Office,"
Legal Aid South Africa",Western Cape,(028) 713 4620,,"17 Heidelberg Road, Riversdale, 6670",www.legal-aid.co.za,,,-34.0921016,21.2563587
Legal Aid SA Malmesbury Local Office,"
Legal Aid South Africa",Western Cape,(022) 482 1784,,"1st Floor, The Frank Building, 47 Voortrekker Road, Malmesbury, 7300",www.legal-aid.co.zaa,,,-33.4638357,18.7289399
Legal Aid SA Atlantis Satellite Office,Legal Aid South Africa,Western Cape,(021) 572 8522,,"Drosdy Centre, Wesfleur Circle, Atlantis",www.legal-aid.co.za,,,-33.562458,18.493638
Legal Aid SA Vredenburg Satellite Office,Legal Aid South Africa,Western Cape,(022) 713 1006,,"1st Floor, Standard Bank Building, 20 Main Street, Vredenburg, 7380",www.legal-aid.co.za,Mr Renaat Bodart,,-32.906597,17.9889717
Legal Aid SA Stellenbosch Local Office,Legal Aid South Africa,Western Cape,(021) 882 9221,,"Office 201, 2nd Floor, Eikestad Mall, Bird Street, Stellenbosch, 7600",www.legal-aid.co.za,Mr Shawn Flowers,,-33.9354089,18.8600011
Legal Aid SA Vredendal Local Office,Legal Aid South Africa,Western Cape,(027) 201 1030,,"9 Dorp Street, Vredendal, 8160",www.legal-aid.co.za,Mr Janus Roothman,,-31.6642884,18.509006
Legal Aid SA Calvinia Satellite Office,Legal Aid South Africa,Western Cape,(027) 341 2240,,"20 Hantam Street, Calvinia",www.legal-aid.co.za,,,-31.4728524,19.7730873
Legal Aid SA Springbok Satellite Office,Legal Aid South Africa,Western Cape,(027) 718 2449,,"Sten Centre, Office 4, 17 Van Riebeeck Street, Springbok, 8240","
www.legal-aid.co.za",,,-29.6682142,17.8820711
Legal Aid SA Worcester Local Office,Legal Aid South Africa,Western Cape,(023) 348 4040,,"1st Floor, WC Building, corner High & Stockenstrom Streets, Worcester, 6850","
www.legal-aid.co.za",Ms Liezel du Toit,,-33.6430813,19.4471276
Legal Aid SA Eastern Cape Provincial Office,Legal Aid South Africa,Eastern Cape,(041) 363 8863,,"5 Mangold Street, Newtown Park, Port Elizabeth, 6001","
www.legal-aid.co.za",Mr Hope Bambiso,,-33.9469013,25.5746076
Legal Aid SA Aliwal North Local Office,Legal Aid South Africa,Eastern Cape,(051) 633 2530,,"67 Somerset Street, Aliwal North, 9750","
www.legal-aid.co.za",Ms Pindiwe Kuzane,,-30.6925055,26.7089553
Legal Aid SA Sterkspruit Satellite Office,Legal Aid South Africa,Eastern Cape,(051) 611 0348,,"79 Main Street, Sterkspruit","
www.legal-aid.co.za",,,-30.524964,27.3645855
Legal Aid SA Butterworth Local Office,Legal Aid South Africa,Eastern Cape,(047) 491 0006,,"ECDC Building, 24 High Street, Butterworth","
www.legal-aid.co.za",Mr Lunga Marenene,,-32.3317255,28.1428484
Legal Aid SA East London Local Office,Legal Aid South Africa,Eastern Cape,(043) 704 4700,,"33 Fleet Street (Commissioner Street entrance), East London, 5200","
www.legal-aid.co.za",Ms Tobeka Maqwati,,-33.0193415,27.9083542
Legal Aid SA Graaff-Reinet Local Office,Legal Aid South Africa,Eastern Cape,(049) 807 2500,,"64 Church Street, Graaff-Reinet, 6280","
www.legal-aid.co.za",Ms Sylvia Mlalandle,,-32.2539695,24.5363523
Legal Aid SA Somerset East Satellite Office,Legal Aid South Africa,Eastern Cape,(042) 243 0610,,"Visser Centre,12 Nojoli Street, Somerset East, 5850","
www.legal-aid.co.za",,,-32.7186873,25.5886927
Legal Aid SA Cradock Satellite Office,Legal Aid South Africa,Eastern Cape,(048) 881 4867,,"Market Square, Adderley Street, Cradock, 5880","
www.legal-aid.co.za",,,-32.1649038,25.6166893
Legal Aid SA Grahamstown Local Office,Legal Aid South Africa,Eastern Cape,(046) 622 9350,,"69 High Street, Grahamstown, 6140","
www.legal-aid.co.za",Ms Imkitha Salman-Beya,,-33.30934,26.526992
Legal Aid SA Fort Beaufort Satellite Office,Legal Aid South Africa,Eastern Cape,(046) 645 2270,,"37 Henrietta Street, Fort Beaufort, 5720","
www.legal-aid.co.za",,,-32.7793216,26.6308294
Legal Aid SA King Williams Town Local Office,Legal Aid South Africa,Eastern Cape,(043) 604 6600,,"Old Mutual Building, 2nd Floor, Corner Cathcart & Mclean Streets, King Williams","
www.legal-aid.co.za",Mr Phumlani Dlamkile,,-32.877851,27.389109
Legal Aid SA Stutterheim Satellite Office,Legal Aid South Africa,Eastern Cape,(043) 683 2973,,"75 Hill Street, Stutterheim, 4930","
www.legal-aid.co.za",,,-32.5700476,27.4220036
Legal Aid SA Mthatha Local Office,Legal Aid South Africa,Eastern Cape,(047) 501 4600,,"PRD Building, 98 Sutherland Street, Mthatha","
www.legal-aid.co.za",Mr Mandla Mngaza,,-31.5880781,28.7842602
Legal Aid SA Lusikisiki Satellite Office,Legal Aid South Africa,Eastern Cape,(039) 253 1442,,"Office No. 9 & 10, Embassy Building, AMCA Road, Lusikisiki","
www.legal-aid.co.za",,,-31.3683458,29.5687646
Legal Aid SA Bizana Satellite Office,Legal Aid South Africa,Eastern Cape,(039) 251 0098,,"
159 Main Street, Bizana","
www.legal-aid.co.za",,,-30.8460263,29.8410174
Legal Aid SA Mount Frere Satellite Office,Legal Aid South Africa,Eastern Cape,(039) 255 1473,,"Erf 106 Canca Street, Mt Frere","
www.legal-aid.co.za",,,-30.8918332,28.9819731
Legal Aid SA Port Elizabeth Local Office,Legal Aid South Africa,Eastern Cape,(041) 408 2800,,"1 Uitenhage Road, North End, Port Elizabeth","
www.legal-aid.co.za",Ms Lynette Franklin,,-33.9351406,25.604419
Legal Aid SA Humansdorp Satellite Office,Legal Aid South Africa,Eastern Cape,(042) 295 2794,,"Tredalux Building, 23 Main Street (at the rear), Humansdorp, 6300","
www.legal-aid.co.za",,,-34.0027188,24.7439749
Legal Aid SA Queenstown Local Office,Legal Aid South Africa,Eastern Cape,(045) 839 3204,,"No 2-4 Shepstone Road, Jeantel Building, Erf 5273, Queenstown, 5319","
www.legal-aid.co.za",Ms Yolundi Ngxola-Tshona,,-31.8950315,26.8718701
Legal Aid SA Elliot Satellite Office,Legal Aid South Africa,Eastern Cape,(045) 931 1984,,"18 Market Street, Elliot, 5460","
www.legal-aid.co.za",,,-31.312988,27.8370041
Legal Aid SA Uitenhage Local Office,Legal Aid South Africa,Eastern Cape,(041) 991 1811,,"32-36 Baird Street, Uitenhage, 6230","
www.legal-aid.co.za",Ms Wongiwe Makhenyane,,-33.768911,25.396019
Legal Aid SA Kirkwood Satellite Office,Legal Aid South Africa,Eastern Cape,(042) 230 0713,,"32B John Street, Kirkwood","
www.legal-aid.co.za",,,-33.4043093,25.4435823
Legal Aid SA KwaZulu-Natal Provincial Office,Legal Aid South Africa,KwaZulu-Natal,(031) 717 8450,,"7th Floor Charter House, Cnr Crompton & Union Street, Pinetown, 3600","
www.legal-aid.co.za",Mr Vela Mdaka,,-29.8142047,30.8618687
Legal Aid SA Durban Local Office,Legal Aid South Africa,KwaZulu-Natal,(031) 304 0100,,"The Marine Building, Ground Floor, 22 Dorothy Nyembe Street (formerly known as Gardiner Street), Durban, 4001","
www.legal-aid.co.za",Ms Nomsa Nkosi,,-29.8609396,31.0254332
Legal Aid Empangeni Local Office,Legal Aid South Africa,KwaZulu-Natal,(035) 792 4949,,"Mazuli House, 7 Maxwell Street, Empangeni, 3008","
www.legal-aid.co.za",Ms Lungile Magoso,,-28.7391548,31.8945141
Legal Aid SA Ingwavuma Satellite Office,Legal Aid South Africa,KwaZulu-Natal,(035) 591 0950,,"Kosibay Hot Chicken complex, shop no. 4, Skhemelele area, Kwangwanase, 3973","
www.legal-aid.co.za",,,-27.0002374,32.7576589
Legal Aid SA Mkuze Satellite Office,Legal Aid South Africa,KwaZulu-Natal,(035) 573 1655,,"Shop 22F, Mkuze Plaza, Old Main Road, Mkuze, 3974","
www.legal-aid.co.za",,,-27.6178353,32.0340229
Legal Aid SA Ladysmith Local Office,Legal Aid South Africa,KwaZulu-Natal,(036) 638 2500,,"65/67 Queens Street, Ladysmith, 3370","
www.legal-aid.co.za",Mr Sthembiso Khumalo,,-28.55886,29.77903
Legal Aid SA Estcourt Satellite Office,Legal Aid South Africa,KwaZulu-Natal,(036) 352 1997,,"Shop No 1, 127 Albert Street, Estcourt, 3310","
www.legal-aid.co.za",,,-29.0073605,29.8750795
Legal Aid SA Newcastle Local Office,Legal Aid South Africa,KwaZulu-Natal,(034) 328 7100,,"65 Scott Street, Newcastle, 2940","
www.legal-aid.co.za",Ms Fathima Buckus,,-27.7578842,29.9321095
Legal Aid SA Dundee Satellite Office,Legal Aid South Africa,KwaZulu-Natal,(034) 212 4460,,"Unit 13, 56 Smith Street, Dundee, 3000","
www.legal-aid.co.za",,,-28.1611175,30.2402181
Legal Aid SA Pietermaritzburg Local Office,Legal Aid South Africa,KwaZulu-Natal,(033) 394 2190,,"187 Hoosen Haffejee Street, Pietermaritzburg, 3201","
www.legal-aid.co.za",Ms Lynn Holtshausen (Acting),,-29.6016077,30.3737363
Legal Aid SA Greytown Satellite Office,Legal Aid South Africa,KwaZulu-Natal,(033) 417 2146,,"Shop No 1, 40 Bell Street, Greytown, 3250","
www.legal-aid.co.za",,,-29.0577638,30.5914417
Legal Aid SA Bulwer Satellite Office,Legal Aid South Africa,KwaZulu-Natal,(039) 832 0515 ,,"Shop 5, Stavcom Building, Main Road, Bulwer","
www.legal-aid.co.za",,,-29.8076573,29.7599761
Legal Aid SA Pinetown Local Office,Legal Aid South Africa,KwaZulu-Natal,(031) 719 2700,,"ABSA Building, 1st Floor, 1 Church Road, Pinetown, 3600","
www.legal-aid.co.za",Mr Vis Nair,,-29.8345022,30.8384093
Legal Aid SA Port Shepstone Local Office,Legal Aid South Africa,KwaZulu-Natal,(039) 688 9600,,"2nd Floor MB Centre, 49 Aiken Street, Port Shepstone, 4240","
www.legal-aid.co.za",Mr Roger Jasson,,-30.737384,30.450675
Legal Aid SA Kokstad Satellite Office,Legal Aid South Africa,KwaZulu-Natal,(039) 727 5081,,"29 Barker Street, Kokstad, 4700","
www.legal-aid.co.za",,,-30.5503243,29.4285202
Legal Aid SA Ixopo Satellite Office,Legal Aid South Africa,KwaZulu-Natal,(039) 834 9392,,"Shop No 3, Ixopo Bottlestore Building, Margaret Street, Ixopo, 3276","
www.legal-aid.co.za",,,-30.1529662,30.0587218
Legal Aid SA Umlazi Local Office,Legal Aid South Africa,KwaZulu-Natal,(031) 942 1002,,"V1332, Ithala Industrial Park, Cnr Mangosuthu Highway & Road 1902, Umlazi, 4031","
www.legal-aid.co.za",Mr Mandla Ntobela,,-29.9575554,30.8346277
Legal Aid SA Umzinto Satellite Office,Legal Aid South Africa,KwaZulu-Natal,(031) 942 1001,,"Office A11, Hypersingh Centre, Cnr Court & Textile Road, Umzinto","
www.legal-aid.co.za",,,-30.307686,30.6727978
Legal Aid SA Verulam Local Office,Legal Aid South Africa,"
KwaZulu-Natal",(032) 533 2654,,"Suite 1, 1st Floor, Ayesha Razak Centre, 23 Groom Street, Verulam, 4340",www.legal-aid.co.za,Mr Sibuko Mashaba,,-29.6437915,31.0486628
Legal Aid SA Stanger Satellite Office,Legal Aid South Africa,KwaZulu-Natal,"
(032) 551 3740",,"Suite 1 Stanger Mall, Stanger Mall, 55 King Shaka Street, Stanger, 4450","
www.legal-aid.co.za",,,-29.3390396,31.2928033
Legal Aid SA Vryheid Local Office,Legal Aid South Africa,KwaZulu-Natal,(034) 989 8300,,"116 Landdrost Street, Vryheid, 3100","
www.legal-aid.co.za",,,-27.7679731,30.7964301
Legal Aid SA Ulundi Satellite Office,Legal Aid South Africa,KwaZulu-Natal,(035) 870 0832,,"Fairbreeze Office Park, Princess Magogo Street, Ulundi, 3838","
www.legal-aid.co.za",,,-28.3000579,31.4262839
Legal Aid SA Free State/North West Provincial Office,Legal Aid South Africa,Free state,(051) 412 8040 ,,"Hydro Park Building, 100 Kellner Street, Westdene, Bloemfontein, 9301","
www.legal-aid.co.za",Mr Tshotlego Makamedi,,-29.10844,26.20648
Legal Aid SA Bethlehem Local Office,Legal Aid South Africa,Free State,(058) 303 6824,,"24 corner Commissioner & Naude Streets, Bethlehem, 9700","
www.legal-aid.co.za",Mr Wouter de Villiers,,-28.2305558,28.30629
Legal Aid SA Ficksburg Satellite Office,Legal Aid South Africa,Free State,(051) 933 3061,,"15 Erwee Street, Ficksburg, 9730","
www.legal-aid.co.za",,,-28.868537,27.8732165
Legal Aid SA Frankfort Satellite Office,Legal Aid South Africa,Free State,(058) 813 1624,,"33 JJ Hadebe Street, Frankfort, 9830","
www.legal-aid.co.za",,,-27.2713332,28.4947236
Legal Aid SA Bloemfontein Local Office,Legal Aid South Africa,Free State,(051) 447 9915,,"Fedsure Building, 4th Floor, 49 Charlotte Maxeke Street, Bloemfontein, 9300","
www.legal-aid.co.za",Mr Machini Motloung,,-29.11751,26.21872
Legal Aid SA Botshabelo Local Office,Legal Aid South Africa,Free State,(051) 534 4398,,"Shop No. 1, Re-A-Hola, Shopping Complex, Botshabelo, 9781","
www.legal-aid.co.za",Mr Muziwandile Nhlapo,,-29.232127,26.7090031
Legal Aid SA Ladybrand Satellite Office,Legal Aid South Africa,Free State,(051) 924 0350,,"30 Joubert Street, Ladybrand, 9745","
www.legal-aid.co.za",,,-29.1919793,27.4588347
Legal Aid SA Kroonstad Local Office,Legal Aid South Africa,Free State,(056) 216 4800,,"99 Murray Street, Kroonstad, 9501","
www.legal-aid.co.za",Ms Anele Tshapa,,-27.6707364,27.2344842
Legal Aid SA Phuthaditjhaba Local Office,Legal Aid South Africa,Free State,(058) 713 5000,,"FDC Building, corner Motloung & Setai Streets, Phuthaditjhaba, 9866","
www.legal-aid.co.za",Mr Tsepo Mosetlhe,,-28.4836381,28.8480284
Legal Aid SA Vrede Satellite Office,Legal Aid South Africa,Free State,(058) 913 3521,,"18A Ras Street, Vrede, 9835","
www.legal-aid.co.za",,,-27.452382,29.1506941
Legal Aid SA Welkom Local Office,Legal Aid South Africa,Free State,(057) 357 2847,,"Archiquant Building, 8 Heeren Street, Welkom, 9460","
www.legal-aid.co.za",Mr Puseletso Mokoena,,-27.9775503,26.7376973
Legal Aid SA Gauteng Provincial Office,Legal Aid South Africa,Gauteng,(012) 664 2921,,"Selborne Centre, 144 Cantonments Road, Lyttelton, Pretoria, 0157","
www.legal-aid.co.za",Advocate Wilna Lambley,,-25.8325588,28.2000128
Legal Aid SA Alexandra Local Office,Legal Aid South Africa,Gauteng,(011) 786 8170,,"Yarona Building, 1st Floor, 89 Watt Avenue, Wynberg, 2090","
www.legal-aid.co.za",Ms Nelisa Soji,,-26.1100843,28.0864151
Legal Aid SA Benoni Local Office,Legal Aid South Africa,Gauteng,(011) 845 4311,,"18 Howard Street, Benoni","
www.legal-aid.co.za",Ms Silindile Hlatshwayo,,-26.1893677,28.3137191
Legal Aid SA Nigel Satellite Office,Legal Aid South Africa,Gauteng,(011) 814 5159,,"Kruger and Okes, 23 Kingsway Street, Nigel","
www.legal-aid.co.za",,,-26.4227879,28.469017
Legal Aid SA Ga-Rankuwa Local Office,Legal Aid South Africa,Gauteng,(012) 700 1416,,"Direla Complex, 222 Main Street, Ga-Rankuwa Industrial, Ga-Rankuwa, 0208","
www.legal-aid.co.za",Mr Lungelo Mdletshe,,-25.5541135,27.9801066
Legal Aid SA Germiston Local Office,Legal Aid South Africa,Gauteng,(011) 842 7300,,"United House, 2nd and 3rd Floor, 175 Meyer Street, corner Meyer and Library","
www.legal-aid.co.za",Ms Aysha Gani,,,
Legal Aid SA Johannesburg Local Office,Legal Aid South Africa,Gauteng,(011) 870 1480,,"56 Main Street, Marshalltown, Johannesburg, 2000","
www.legal-aid.co.za",Ms Nokuphiwa Nguqu,,-26.20758,28.03718
Legal Aid SA Krugersdorp Local Office,Legal Aid South Africa,Gauteng,(011) 660 2335,,"51 Burger Street, Krugersdorp, 1739","
www.legal-aid.co.za",Mr Stephen Mofokeng,,-26.1041029,27.771111
Legal Aid SA Pretoria Local Office,Legal Aid South Africa,Gauteng,(071) 084 6750,,"Locarno House, 4th Floor, 317 Francis Baard Street, Pretoria","
www.legal-aid.co.za",Ms Flavia Isola,,-25.7490943,28.194323
Legal Aid SA Soshanguve Local Office,Legal Aid South Africa,Gauteng,(012) 797 2721,,"Medicos Centre, Stand No. 2093, Block H, Soshanguve, 0152","
www.legal-aid.co.za",Mr Michael Ramaphosa,,-25.5237167,28.1101742
Legal Aid SA Soweto Local Office,Legal Aid South Africa,"
Gauteng",(011) 938 3547,,"Maponya Mall, Office 403 & 411A, Chris Hani Road, Klipspruit","
www.legal-aid.co.za",Mr Mmeli Motsepe,,-26.260172,27.8986258
Legal Aid SA Westonaria Satellite Office,Legal Aid South Africa,Gauteng,(011) 753 2604,,"Elizabeth Centre, Shop No. 3, President Steyn Street, Westonaria, 1779","
www.legal-aid.co.za",,,-26.3178076,27.6505374
Legal Aid SA Tembisa Local Office,Legal Aid South Africa,Gauteng,(011) 316 0197,,"Tembisa MegaMart Mall, Shop 42, Botswana Road, Tswelopelo Section, Tembisa","
www.legal-aid.co.za",Advocate Elizabeth Nieuwoudt,,-25.9633817,28.2117947
Legal Aid SA Vereeniging Local Office,Legal Aid South Africa,Gauteng,(016) 421 3527,,"8 Jasmine Mansions, 28 Senator Marks Avenue, Vereeniging, 1938","
www.legal-aid.co.za",Mr Thami Manaba,,-26.677056,27.9282432
Legal Aid SA Heidelberg Satellite Office,Legal Aid South Africa,Gauteng,(016) 341 4430,,"Eslin Building, Portion 4, 24 Uekerman Street, Heidelberg, 1441","
www.legal-aid.co.za",,,-26.4987679,28.354981
Legal Aid SA Limpopo/Mpumalanga Provincial Office,Legal Aid South Africa,Mpumalanga,(015) 296 0117,,"Number 3 Cormar Park, Rhodes Drift Avenue, Bendor, Polokwane, 0700","
www.legal-aid.co.za",Ms Mpho Kgabi,,-23.8913958,29.4870201
Legal Aid SA Ermelo Local Office,Legal Aid South Africa,Mpumalanga,(017) 819 7291,,"14 Joubert Street, Ermelo, 2350","
www.legal-aid.co.za",Ms Shameema Essak (Acting),,-26.5219185,29.9847801
Legal Aid SA Piet Retief Satellite Office,Legal Aid South Africa,Mpumalanga,(017) 826 4567,,"14 Kruger Street, Piet Retief, 2380","
www.legal-aid.co.za",,,-27.0045602,30.8029157
Legal Aid SA Eerstehoek Satellite Office,Legal Aid South Africa,Mpumalanga,(017) 883 7931,,"Stand Number 88, Nhlazatshe G, Elukwatini, 1192","
www.legal-aid.co.za",,,-26.06274493,30.7651654
Legal Aid SA Middelburg Local Office,Legal Aid South Africa,Mpumalanga,(013) 243 5964,,"5A Laver Street, Middelburg, 1050","
www.legal-aid.co.za",Ms Jacobeth Rampa,,-25.7679756,29.4527084
Legal Aid SA Groblersdal Satellite Office,Legal Aid South Africa,Mpumalanga,(013) 262 4770 ,,"Eldorado 7, Voortrekker Street, Groblersdal, 470","
www.legal-aid.co.za",,,-25.1702041,29.399997
Legal Aid SA Siyabuswa Satellite Office,Legal Aid South Africa,Mpumalanga,(013) 973 3963,,"1664 A Maqhawe street, Siyabuswa","
www.legal-aid.co.za",,,-25.1104937,29.0660364
Legal Aid SA Nelspruit Local Office,Legal Aid South Africa,Mpumalanga,(013) 756 4500,,"Mpumalanga High Court Building, 3rd Floor, East Wing, Samora Machel Drive, Nelspruit, 1200","
www.legal-aid.co.za",Mr Sipho Ndhlovu,,-25.4731632,30.9615781
Legal Aid SA Burgersfort Satellite Office,Legal Aid South Africa,Mpumalanga,(013) 231 7058,,"Suite 2, Umshanti Building, Ground Floor, 83 Aloe Street, Aloe Ridge West, 1150","
www.legal-aid.co.za",,,-34.0186735,20.5396087
Legal Aid SA Bushbuckridge Satellite Office,Legal Aid South Africa,Mpumalanga,(013) 799 1832,,"R40 Road, Protea Building, Bushbuckridge, 1280","
www.legal-aid.co.za",,,-24.7829787,31.0664103
Legal Aid SA Witbank Local Office,Legal Aid South Africa,Mpumalanga,(013) 656 5290,,"No. 17, corner Northey & Botha Streets, Witbank, 1035","
www.legal-aid.co.za",Mr Evans Mabitsela,,-25.875998,29.216408
Legal Aid SA Secunda Satellite Office,Legal Aid South Africa,Mpumalanga,(017) 634 8532,,"2nd Floor, Old Mutual Building, Hoorwood Street, Secunda Complex, Secunda, 2302","
www.legal-aid.co.za",,,-26.5061748,29.186101
Legal Aid SA Kwa-Mhlanga Satellite Office,Legal Aid South Africa,"
Mpumalanga",(013) 947 3379,,"Erf 538/7, Kwa-Mhlanga, Bankview","
www.legal-aid.co.za",,,-25.4358396,28.7043433
Legal Aid SA Limpopo/Mpumalanga Provincial Office,Legal Aid South Africa,Limpopo,(015) 296 0117,,"Number 3 Cormar Park, Rhodes Drift Avenue, Bendor, Polokwane, 0700","
www.legal-aid.co.za",Ms Mpho Kgabi,,-23.8913958,29.4870201
Legal Aid SA Makhado Local Office,Legal Aid South Africa,Limpopo,(015) 519 1100,,"Noor Gardens, Cnr Krogh & Devenish Street, Louis Trichardt, 0920","
www.legal-aid.co.za",Mr Elton Makhadi,,-23.0378139,29.90839
Legal Aid SA Musina Satellite Office,Legal Aid South Africa,Limpopo,(015) 533 1691,,"Stand No 2110, Nkhata Cresent Street, Nancefield Extension 5, Musina, 0900","
www.legal-aid.co.za",,,-22.3384821,30.0174067
Legal Aid SA Modimolle Local Office,Legal Aid South Africa,Limpopo,(014) 717 4977,,"Shop 8, Nyl Sake Sentrum, Nelson Mandela Drive, Modimolle, 0510","
www.legal-aid.co.za",Ms Confidence Mphaho (Acting),,-24.6976714,28.4023086
Legal Aid SA Lephalale Satellite Office,Legal Aid South Africa,Limpopo,(014) 763 1433,,"2 Booysen Street, 1st floor Stamax Building, 0555","
www.legal-aid.co.za",,,-23.67688,27.73907
Legal Aid SA Polokwane Local Office,Legal Aid South Africa,Limpopo,(015) 291 2429,,"Polokwane High Court, 3rd Floor, Cnr Bodenstein & Biccard Street, Polokwane, 0700","
www.legal-aid.co.za",Mr Thabo Ledwaba,,-23.9130241,29.4575372
Legal Aid SA Mokopane Satellite Office,Legal Aid South Africa,Limpopo,(015) 491 4461,,"Rayveen Complex, 58 Rabe Street, Mokopane, 0626","
www.legal-aid.co.za",,,-24.183249,29.0176692
Legal Aid SA Thohoyandou Local Office,Legal Aid South Africa,Limpopo,(015) 962 6206,,"Shop 2, Metropolitan Complex, Thohoyandou, 0950","
www.legal-aid.co.za",Mr Thomas Madzaga,,-22.9823184,30.447285
Legal Aid SA Tzaneen Local Office,Legal Aid South Africa,Limpopo,(015) 307 3129,,"Office 220-237, Tzaneen Crossing Mall, 2nd Floor, Cnr Lydenburg & Skurving Str, Tzaneen","
www.legal-aid.co.za",Ms Tsakani Maluleke-Sihlangu,,-23.8303485,30.1622887
Legal Aid SA Giyani Satellite Office,Legal Aid South Africa,Limpopo,(015) 812 3065,,"Ground Floor, Old Mutual Building, Giyani Main Road, Giyani, 0826","
www.legal-aid.co.za",,,-23.3103645,30.6913397
Legal Aid SA Phalaborwa Satellite Office,Legal Aid South Africa,Limpopo,(015) 781 0960,,"Shop 4, 32 Wildevey Street, Phalaborwa, 1390","
www.legal-aid.co.za",,,-23.9487817,31.1375219
Legal Aid SA Free State/North West Provincial Office,Legal Aid South Africa,North West,(051) 412 8040 ,,"Hydro Park Building, 100 Kellner Street, Westdene, Bloemfontein, 9301","
www.legal-aid.co.za",Mr Tshotlego Makamedi,,-29.10844,26.20648
Legal Aid SA Klerksdorp Local Office,Legal Aid South Africa,North West,(018) 464 3022,,"19 Bram Fischer Street, Klerksdorp, 2570","
www.legal-aid.co.za",Ms Melinda de Kock,,-26.8667149,26.6665466
Legal Aid SA Wolmaransstad Satellite Office,Legal Aid South Africa,North West,(018) 596 2775,,"Shop No. 12, Kruger Walk Building, 34C Kruger Street, Wolmaransstad, 2630","
www.legal-aid.co.za",,,-27.1974404,25.98112
Legal Aid SA Lichtenburg Local Office,Legal Aid South Africa,North West,(018) 632 7600,,"25 Bantjies Street, Lichtenburg, 2740","
www.legal-aid.co.za",Mr Theriso Mokgwatlheng,,-26.1459543,26.1607992
Legal Aid SA Delareyville Satellite Office,Legal Aid South Africa,North West,(053) 948 1092,,"49 Goewerment Street, Delareyville, 2770","
www.legal-aid.co.za",,,-26.6860746,25.4541771
Legal Aid SA Mafikeng Local Office,Legal Aid South Africa,North West,(018) 381 4582 ,,"Borekelong House, behind Mega City Complex, next to Department of Home Affairs, Mafikeng, 2745","
www.legal-aid.co.za",Ms Victoria Mophulane,,-25.855978,25.64031
Legal Aid SA Potchefstroom Local Office,Legal Aid South Africa,North West,(018) 293 0045,,"44 Dr James Moroka Street, Royal Building,2nd Floor, Potchefstroom, 2531","
www.legal-aid.co.za",Mr Moima Phakedi,,-26.7245693,27.062238
Legal Aid SA Carletonville Satellite Office,Legal Aid South Africa,North West,(018) 787 2346,,"49 A van Zyl, Smit Street, Oberholzer, 2500","
www.legal-aid.co.za",,,-26.3465119,27.389635
Legal Aid SA Rustenburg Local Office,Legal Aid South Africa,North West,(014) 592 3185 ,,"122 Beyers Naude Drive; Albach Building, Rustenburg, 0299","
www.legal-aid.co.za",Ms Charmain Smit,,-25.6705051,27.2458676
Legal Aid SA Vryburg Local Office,Legal Aid South Africa,North West,(053) 927 0095 ,,"71 Vry Street, Vryburg, 8601","
www.legal-aid.co.za",Mr Aubrey Kunene,,-26.95988,24.7262522
Legal Aid SA Northern Cape/Western Cape Provincial Office,Legal Aid South Africa,Northern Cape,(021) 861 3000,,"Shiraz House, Brandwacht Office Park, Trumali Street, Stellenbosch, 7600","
www.legal-aid.co.za",Ms Nolitha Jali,,-33.9572549,18.8576253
Legal Aid SA Colesberg Local Office,Legal Aid South Africa,Northern Cape ,(051) 753 2281,,"8 Campbell Street, Colesberg, 9795","
www.legal-aid.co.za",Ms Lerato Maake,,-30.7197565,25.0988108
Legal Aid SA De Aar Satellite Office,Legal Aid South Africa,Northern Cape ,(053) 631 1950,,"47 Church Street, De Aar, 7000","
www.legal-aid.co.za",,,-30.6503866,24.0094615
Legal Aid SA Victoria West Satellite Office,Legal Aid South Africa,Northern Cape ,(053) 621 0039,,"14 Church Street, Victoria West, 7070","
www.legal-aid.co.za",,,-31.403954,23.117745
Legal Aid SA Kimberley Local Office,Legal Aid South Africa,Northern Cape ,(053) 832 2348,,"Pretmax Building, 4 Sydney Street, Kimberley, 8301","
www.legal-aid.co.za",Mr Motsamai Tlisane,,-28.7426882,24.7634315
Legal Aid SA Hartswater Satellite Office,Legal Aid South Africa,Northern Cape ,(053) 474 0341,,"Checkers Centre, 5 DF Malan Street, Hartswater, 8570","
www.legal-aid.co.za",,,-27.7537535,24.8061633
Legal Aid SA Upington Local Office,Legal Aid South Africa,Northern Cape ,(054) 337 9200,,"11 Lutz Street, Upington, 8800","
www.legal-aid.co.za",Ms Annemi Duursema-Joubert,,-28.4583898,21.2447962
Legal Aid SA Kuruman Satellite Office,Legal Aid South Africa,Northern Cape ,(053) 712 3782,,"33 Bear Street, Kuruman, 8460","
www.legal-aid.co.za",,,-27.4628007,23.4322869
Legal Aid SA Postmasburg Satellite Office,Legal Aid South Africa,"
Northern Cape ",(053) 313 3642,,"22 Boe Street, Postmasburg, 8420","
www.legal-aid.co.za",,,-28.3092768,23.1084191
Legal Aid SA Prieska Satellite Office,Legal Aid South Africa,Northern Cape ,(053) 353 1184,,"37 Main Street, Prieska, 8940","
www.legal-aid.co.za",,,-29.6659785,22.7503535
Rental Housing Tribunal National Office,Rental Housing Tribunal,Western Cape;Eastern Cape;KwaZulu-Natal;Free state;Gauteng;Mpumalanga;Limpopo;North West;Northern Cape,(012) 444 5212,ronald.mukhombo@gov.za,"539 Stanza Bopape Street, Arcadia, Pretoria",http://www.dhs.gov.za,Mr. Ronald Mukhombo,,-25.7454427,28.2053678
Rental Housing Tribunal Western Cape Provincial Office,Rental Housing Tribunal,Western Cape,(021) 483 5020,rht.disputes@westerncape.gov.za,"9 Wale St, Cape Town City Centre, Cape Town, 8000",www.westerncape.gov.za,,,-33.9241632,18.418636
Rental Housing Tribunal Eastern Cape Provincial Office,Rental Housing Tribunal,Eastern Cape,(043) 711 9622 ,mbulelop@ecdhs.gov.za,"31- 33 Phillip Frame Road Waverly Park, Chislehurst. East London ",http://www.dhs.gov.za,Mr Mbulelo Plaatjie,,-32.9873041,27.8905708
Rental Housing Tribunal KwaZulu-Natal Provincial Office,Rental Housing Tribunal,KwaZulu-Natal,(031) 372 1822 ,Hlengiwe.chili@kzndhs.gov.za,"353-363 Pexley Ka Seme Street, Durban. ",http://www.dhs.gov.za,Ms Hlengiwe Chili,,-29.8589136,31.0227405
Rental Housing Tribunal Free State Provincial Office,Rental Housing Tribunal,Free State,(051) 405 4965 ,Ofentse@fshs.gov.za,"Lebogang BuildingCnr Markgraaf & St Andrews street, Bloemfontein",http://www.dhs.gov.za,Ms. Ofentse Seate,,-29.1166871,26.2205783
Rental Housing Tribunal Gauteng Provincial Office ,Rental Housing Tribunal,Gauteng,(011) 630 5001,betty.kgobe@gauteng.gov.za,Ikusasa House 4th Floor 129 Fox Street. Johannesburg,http://www.dhs.gov.za,Ms. B. Kgobe,,-26.2055696,28.0446124
Rental Housing Tribunal Mpumalanga Provincial Office,Rental Housing Tribunal,Mpumalanga,(013) 757 0792 ,rentaltribunal@mpg.gov.za,"Cnr Emontweni Avenue and Kaewldorp, Mbombela.",http://www.dhs.gov.za,Mr. R. Ledwaba,,-25.4752984,30.9694163
Rental Housing Tribunal Limpopo Provincial Office,Rental Housing Tribunal,Limpopo,(015) 284 5426 ,manganyehg@coghsta.limpopo.gov.za,20 Rabe & Lanfros Mare Street. Polokwane.,http://www.dhs.gov.za,Ms. Gladys Manganye,,-23.8726825,29.4640763
Rental Housing Tribunal North West Provincial Office,Rental Housing Tribunal,North West,(018) 388 5531,smoagi@nwpg.gov.za,"University Drive Building, 2nd floor. Mafikeng ",http://www.dhs.gov.za,Ms. S. Moagi,,-25.855978,25.64031
Rental Housing Tribunal Northern Cape Provincial Office,Rental Housing Tribunal,Northern Cape ,(053) 830 9572 ,lpaul@ncpg.gov.za,"9 Cecil Sussman Road, Kimberley",http://www.dhs.gov.za,Mr. L. Paul,,-28.729589,24.7610648
Legal Practice Council National Office,Legal Practice Council,Western Cape;Eastern Cape;KwaZulu-Natal;Free state;Gauteng;Mpumalanga;Limpopo;North West;Northern Cape,(010) 001 8500,info@lpc.org.za,"94 Bekker Road, Vorna Valley, Midrand, 1685",https://lpc.org.za,,,-26.0098862,28.1136891
Legal Practice Council Western Cape Provincial Office,Legal Practice Council,Western Cape,(021) 443 6751,infowc@lpc.org.za,"26th & 27th Floors, Foreshore Place, A 2 Riebeek Street, Cape Town,  8001.",https://lpc.org.za,,,-33.9203785,18.4243079
Legal Practice Council Eastern Cape Provincial Office,Legal Practice Council,Eastern Cape,(043) 050 1025,infoec@lpc.org.za,"44 Pearce Street, Berea, East London",https://lpc.org.za,,,-32.9931098,27.916192
Legal Practice Council KwaZulu-Natal Provincial Office,Legal Practice Council,KwaZulu-Natal,(033) 345 1304,infokzn@lpc.org.za,"1ST Floor, 200 Hoosen Haffejee Street, Pietermaritzburg, 3201",https://lpc.org.za,,,-29.6014211,30.3748846
Legal Practice Council Free State Provincial Office,Legal Practice Council,Free State,(051) 4473237,infofs@lpc.org.za,"139 Zastron Street, Westdene, Bloemfontein, 9300",https://lpc.org.za,,,-29.11122,26.2097657
Legal Practice Council Gauteng Provincial Office,Legal Practice Council,Gauteng,(012) 338 5800,infogp@lpc.org.za,"Procforum Building, 123 Paul Kruger Street, Pretoria,0002",https://lpc.org.za,,,-25.744159,28.187434
Legal Practice Council Mpumalanga Provincial Office,Legal Practice Council,Mpumalanga,(017) 200 2487,infomp@lpc.org.za,"18 Bell Street, Bell towers, Ground Floor, Nelspruit",https://lpc.org.za,,,-25.4713586,30.9804997
Legal Practice Council Limpopo Provincial Office,Legal Practice Council,Limpopo,(015) 590 0389,infolp@lpc.org.za,"2 Biccard Street, Unit 2 JCJ Building, Polokwane, 0700",https://lpc.org.za,,,-23.9192399,29.45893
Legal Practice Council North West Provincial Office,Legal Practice Council,North West,(018) 011 0093,infonw@lpc.org.za,"No. 2 Aerodrome Crescent, Mahikeng",https://lpc.org.za,,,-25.8435558,25.6374459
Legal Practice Council Northern Cape Provincial Office,Legal Practice Council,Northern Cape ,(053) 0500508,infonc@lpc.org.za,"219 Du Toitspan Street, Belgravia, Kimberley",https://lpc.org.za,,,-28.74845,24.77749
UCT Law Clinic,University Law Clinics,Western Cape,(021) 650 3775,uctlawclinic@uct.ac.za,"Room 436 4th Floor Kramer Law School, UCT Middle Campus Rondebosch, Cape Town, 7700",,,,-33.9575279,18.465148
UCT Refugee Law Centre,University Law Clinics,Western Cape,(021) 650 3775,refugeelawclinic@uct.ac.za,"Unit 4th Floor Kramer Law School, UCT Middle Campus",,,,-33.9575279,18.465148
UWC Law Clinic,University Law Clinics,Western Cape,(021) 959 2756,edurojaye@uwc.ac.za,"Old Library Building, University of the Western Cape, Modderdam Rd, Bellville, Cape Town, 7530, South Africa",,,,-33.9335203,18.627958
University of Fort Hare Legal Clinic,University Law Clinics,Eastern Cape,(043) 704 7520,pzinto@ufh.ac.za,"6th Floor, Gasson Centre, 50 Church Street, CBD, East London, 5201",www.ufh.ac.za/faculties/law/,N Chetty,,-33.0205195,27.9073109
Rhodes University Law Clinic,University Law Clinics,Eastern Cape,(046) 603 7656,lawclinic@ru.ac.za,"41 New Street, Grahamstown, 6139",www.ru.ac.za/lawclinic,R McDonald,,-33.3098775,26.5227602
Nelson Mandela University Law Clinic,University Law Clinics,Eastern Cape,(041) 504 1111,sonia.yawa@mandela.ac.za ,"Nelson Mandela University, Gqeberha, 6031, South Africa",www.crimproclaw.mandela.ac.za/Law-Clinic#,Sonia Yawa,,-33.9608369,25.6022423
Walter Sisulu University,University Law Clinics,Eastern Cape,(047)531 6075,lawclinic@wsu.ac.za,"WSU Admin Block, Walter SIsulu University, Nelson Mandela Drive Campus, Mthatha",,Mr V Nosindwa,,-31.5938528,28.7621768
University of the Free State Law Clinic,University Law Clinics,Free State,(051) 401 9971,antohniep@ufs.ac.za,"Room C206, Mabaleng Building, Block B, C/o Nelson Mandela Drive & Sport Avenue, UFS Main Campus, Bloemfontein",http://law.ufs.ac.za/,Christopher Rawson,,-29.1078999,26.1821454
University of the Witwatersrand Law Clinic,University Law Clinics,Gauteng,(011) 717 8562,wendy.miya@wits.ac.za,"1 Jan Smuts Avenue, Braamfontein, Wits University, West Campus",https://www.wits.ac.za/lawclinic/11045/witslawclinic.html,W Miya,,-26.1928836,28.0304733
University of Johannesburg Law Clinic,University Law Clinics,Gauteng,(011) 559 2633,magdao@uj.ac.za,"UJ Auckland Park Kingsway Campus Law Clinic, Masada Building",http://www.uj.ac.za,N Naidoo,,-26.1826272,28.0039326
University of Pretoria Law Clinic,University Law Clinics,Gauteng,(012) 420 5910,francina.ngidi@up.ac.za,"University of Pretoria Law Clinic, 1107 South Street, Hatfield, Pretoria, 0002",https://www.up.ac.za/up-law-clinic-home-page,Mrs Francina Ngidi,,-25.753886,28.2360645
UNISA Law Clinic,University Law Clinics,Gauteng,(012) 481 2954,LawClinic@unisa.ac.za,"12A/RG21 Joubert St, Sunnyside, Pretoria, 0002, South Africa","https://www.unisa.ac.za/sites/corporate/default/Colleges/Law/Schools,-departments,-centres-&-institute/Unit/Unisa-Law-Clinic",,,-25.7555449,28.199673
Vaal University of Technology Law Clinic,University Law Clinics,Gauteng,(016) 950 9000,,"Andries Potgieter Blvd, Private Bag X021, Vanderbijlpark, 1900, Gauteng, South Africa",www.vut.ac.za/supervising-attorney-law-clinic/,,,-26.7161786,27.8630845
University of KwaZulu-Natal Law Clinic,University Law Clinics,KwaZulu-Natal,(031) 260 2446,ukznlawclinic@gmail.com,"269 Mazisi Kunene Rd, Durban, 4001",http://www.ukzn.ac.za/,,,-29.8586067,30.9863246
University of Venda Law Clinic,University Law Clinics,Limpopo,(015) 962 8634,Tshifhiwa.tshishonga@univen.ac.za,"School of Law Building, Office No F030, University Road,Thohoyandou, Limpopo",http://www.univen.ac.za/index.php?Entity=Legal%20Aid%20Clinic&Sch=6,,,-22.878541,30.4818446
University of Limpopo Law Clinic,University Law Clinics,Limpopo,(015) 268 2506,Siphiwe.Sikhakhane@ul.ac.za,"Office No 16, Ground Floor, R Block, No 1, University Road, Mankweng",www.ul.ac.za,,,-23.8874526,29.7311797
Stellenbosch University Law Clinic,University Law Clinics,Western Cape,(021) 808 3600,rhkadmin@sun.ac.za,"418-24 Crozier Street, Stellenbosch, Western Cape, 7600",www.sulawclinic.co.za,,,-33.9330605,18.8610145
Ndifuna Ukwazi Law Centre (NU),NGO Law Clinics,Western Cape,(021) 012 5094,contact@nu.org.za,"18 Roeland St, Cape Town City Centre, Cape Town, 7100",www.nu.org.za,,,-33.9288122,18.4205201
Legal Resources Centre (LRC),NGO Law Clinics,Western Cape,(021) 879 2398,info@lrc.org.za,"Aintree Office Park, Block D, Ground Floor, Corner Doncaster Road and Loch Road, Kenilworth, Cape Town, 7708",www.lrc.org.za,,,-33.9910878,18.4843315
Legal Resources Centre (LRC) National Office ,NGO Law Clinics,Western Cape;Eastern Cape;KwaZulu-Natal;Free state;Gauteng;Mpumalanga;Limpopo;North West;Northern Cape,(011) 038 9709,info@lrc.org.za,"2nd Floor West Wing, Women’s Jail, Constitution Hill, 1 Kotze Street, Braamfontein",www.lrc.org.za,,,-26.1903118,28.0417696
Legal Resources Centre (LRC),NGO Law Clinics,KwaZulu-Natal,(031) 301 7572,info@lrc.org.za,"11th Floor, Aquasky Towers, 275 Anton Lembede Street, Durban, 4001",www.lrc.org.za,,,-29.8594151,31.0253277
Legal Resources Centre (LRC),NGO Law Clinics,Eastern Cape,(046) 622 9230,info@lrc.org.za,"116 High Street, Makhanda, 6139",www.lrc.org.za,,,-33.3123042,26.5232846
Socio-Economic Rights Institute of South Africa (SERI),NGO Law Clinics,Gauteng,(011) 356 5860,sanele@seri-sa.org,"6th floor Aspern House, 54 De Korte Street, Braamfontein 2001, Johannesburg, South Africa",https://www.seri-sa.org/index.php,,,-26.1940707,28.0330274
ProBono.Org,NGO Law Clinics,Gauteng,(011) 339 6080,info@probono.org.za,"1st Floor West Wing, Women’s Jail, Constitution Hill, 1 Kotze Street, Braamfontein, Johannesburg. ",www.probono.org.za,,,-26.1903118,28.0417696
ProBono.Org,NGO Law Clinics,KwaZulu-Natal,(031) 301 6178,dbninfor@probono.org.za,"303 Anton Lembede Street, Suite 701, 7th Floor, Durban Club Chambers, Durban, 4001. ",www.probono.org.za,,,-29.8601043,31.0242288
ProBono.Org,NGO Law Clinics,Western Cape,(086) 665 6740,infocpt@probono.org.za,"Suite 200, Second Floor, 57 On Strand, Strand Street",www.probono.org.za,,,-33.9203404,18.4179814
Lawyers for Human Rights (LHR) Law Clinics,NGO Law Clinics,Gauteng,(012) 320 2943,info@lhr.org.za,"Kutlwanong Democracy Centre, 357 Visagie Street, Pretoria",www.lhr.org.za,Thato Gaffane,,-25.7524375,28.1963768
Lawyers for Human Rights (LHR) Law Clinics,NGO Law Clinics,Gauteng,(011) 339 1960,info@lhr.org.za,"4th Floor Southpoint Corner Building, 87 De Korte Street, Braamfontein",www.lhr.org.za,Ratanang Lekgwata,,-26.1932475,28.0361279
Lawyers for Human Rights (LHR) Law Clinics,NGO Law Clinics,Western Cape,(021) 424 8561,info@lhr.org.za,"295 Lower Main Road, Observatory, Cape Town",www.lhr.org.za,Ntombentsha Zwane,,-33.9329272,18.4662975
Lawyers for Human Rights (LHR) Law Clinics,NGO Law Clinics,Limpopo,(015) 534 2203,info@lhr.org.za,"18 Watson Avenue, Musina, Limpopo",www.lhr.org.za,Ratanang Lekgwata,,-22.3812506,30.0318547
Lawyers for Human Rights (LHR) Law Clinics,NGO Law Clinics,KwaZulu-Natal,(031) 301 0531,info@lhr.org.za,"Room S104, Diakonia Centre, 20th Diakonia Avenue (formerly St. Andrews Street), Durban ",www.lhr.org.za,Dieudonne Bushabu,,-29.862455,31.017674
The Legal Advisory and Information Centre (LAIC),NGO Law Clinics,Gauteng,(067) 681 3260,info@laic.org.za,"Kopanong Community Centre, 2332 Luthuli Street, Dobsonville, Soweto",http://laic.org.za/,,,-26.22332,27.86315
Haki Legal Clinic,NGO Law Clinics,Gauteng,(067) 070 5130,info@haki.org.za,"Johannesburg Chamber of Commerce House, 5th floor, 27 Owl Street (corner Empire & Owl St), Milpark, Johannesburg, 2092.",http://haki.org.za,,,-26.1838027,28.0196148
Unizulu Law Clinic,NGO Law Clinics,KwaZulu-Natal,(035) 902 6011,NgobeseM@unizulu.ac.za,University of Zululand,http://www.unizulu.ac.za/faculties/faculty-of-commerce/4about-us/,Mbuyiselo Vilakazi,,-28.7575813,32.0496905
North West Mahikeng Law Clinic,NGO Law Clinics,North West,(018) 389 2595,Simon.rasikhalela@nwu.ac.za,"Corner Albert Luthuli & University Drive,Block A2B Base Building Mmabatho 2735",http://law.nwu.ac.za/Community-Law-Centre,,,-25.8377623,25.594752
North West University Potchefstroom Law Clinic,NGO Law Clinics,North West,(018) 297 5341,Regskliniek-Potch@nwu.ac.z,1st floor GKSA Administrative Building C/o Thabo Mbeki Drive & Meyer Street,http://law.nwu.ac.za,,,-26.6916039,27.0948803
Mpumalanga Access to Justice Cluster (North West University),NGO Law Clinics,Mpumalanga,(018) 297 5341,Regskliniek-Potch@nwu.ac.z,1st floor GKSA Administrative Building C/o Thabo Mbeki Drive & Meyer Street,http://law.nwu.ac.za,,,-26.6916039,27.0948803